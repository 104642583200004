import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'MCAT Packages',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
                  <div className={tilesClasses}>
                      <div id="bottom" class="body-wrap"></div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                                  <p className="text-sm mb-0">
                                      <center>
                                      <h4>
                                              Basic Package
                                          </h4>
                                          <h5>
                                              4 weeks
                                              </h5>
                                          <h5>
                                              5 days per week
                                          </h5>
                                          <h5>
                                              $60 per hour
                                          </h5>
                                          <h5>
                                              $1200
                                              </h5>
                                          </center>
                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                    
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                                      <center>
                                          <h4>
                                              Balanced Package <span className="text-color-primary">(Most Popular)</span>
                                          </h4>
                                          <h5>
                                              16 weeks
                                              </h5>
                                          <h5>
                                              4 days per week
                                              </h5>
                                          <h5>
                                              $50 per hour
                                          </h5>
                                          <h5>
                                              $3200
                                          </h5>
                                      </center>
                                                     </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                                      <center>
                                          <h4>
                                              Long Term Package <span className="text-color-primary">(Most Value)</span>
                                          </h4>
                                          <h5>
                                              24 weeks
                                              </h5>
                                          <h5>
                                              4 days per week
                                          </h5>
                                          <h5>
                                              $45 per Hour
                                              </h5>
                                          <h5>
                                              $4320
                                          </h5>
                                      </center>                      </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-link">
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;